
import { defineComponent, inject } from 'vue';
import {
    IonRouterOutlet,
    IonPage
} from '@ionic/vue';
import { Store } from '@/stores';
import useConfigs from '@/services/config';
import Header from '@/components/Header/Header.vue';
import MessagesHqModal from '@/components/Messages/MessagesHqModal.vue';
import SearchModal from '@/modals/SearchModal.vue';
import PauseShopModal from '@/modals/PauseShopModal.vue';

export default defineComponent({
    name: 'Curbside',

    components: {
        IonRouterOutlet,
        IonPage,
        Header,
        MessagesHqModal,
        SearchModal,
        PauseShopModal,
    },

    data() {
        return {
            configs: useConfigs(),
            showCurbsideToggle: true,
            showCurbside: true,
            // notificationsAreOpen: false,
            alertMessage: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
            showSearch: false,
            showPauseShop: false,
        };
    },

    methods: {
        // toggleNotifications(visible: boolean) {
        //     this.notificationsAreOpen = visible;
        // },
        showAlertInfo() {
            // TODO: Handle clicking the "view" button in the alert banner
            this.alertMessage = '';
        },
        async toggleSearch(visible: boolean) {
            console.log('toggleSearch');
            this.showSearch = visible ?? !this.showSearch;
        },
        async togglePauseShop(visible: boolean) {
            this.showPauseShop = visible ?? !this.showPauseShop;
        },
    },
    setup() {
        const store = inject('store') as Store;
        return {
            store
        }
    },
});
