
import { defineComponent, inject, toRefs, watch, onMounted, ref } from 'vue';
import { Store } from '@/stores';
import { IonButton } from '@ionic/vue';

export default defineComponent({
    name: 'PauseButton',

    components: {
        IonButton,
    },
    setup(): object {
        const store = inject('store') as Store;
        const { isPaused } = toRefs(store.pauseShopData);
        const text = ref('Pause Shop');

        const getText = () => {
            text.value = isPaused.value ? "Paused" : "Pause Shop";
        };

        onMounted(getText)

        watch(() => isPaused.value, getText);

        return {
            text,
            getText,
            isPaused,
        };
    },
});
