import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Header = _resolveComponent("Header")
  const _component_ion_router_outlet = _resolveComponent("ion-router-outlet")
  const _component_messages_hq_modal = _resolveComponent("messages-hq-modal")
  const _component_SearchModal = _resolveComponent("SearchModal")
  const _component_PauseShopModal = _resolveComponent("PauseShopModal")
  const _component_ion_page = _resolveComponent("ion-page")

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_Header, {
        back: false,
        "show-curbside-toggle": _ctx.showCurbsideToggle,
        "onNotifications-toggle": _cache[1] || (_cache[1] = $event => (_ctx.store.toggleShowHqModal($event))),
        "alert-message": _ctx.alertMessage,
        "alert-button-text": "View",
        "onAlert-click": _ctx.showAlertInfo,
        "onOpen-search": _ctx.toggleSearch,
        "onOpen-pause-shop": _ctx.togglePauseShop
      }, null, 8, ["show-curbside-toggle", "alert-message", "onAlert-click", "onOpen-search", "onOpen-pause-shop"]),
      _createVNode(_component_ion_router_outlet),
      (_ctx.configs.featureFlags().showHqMessages)
        ? (_openBlock(), _createBlock(_component_messages_hq_modal, {
            key: 0,
            id: "notifications-hq-modal",
            "is-open": _ctx.store.state.showHqModal,
            "onNotifications-toggle": _cache[2] || (_cache[2] = $event => (_ctx.store.toggleShowHqModal($event)))
          }, null, 8, ["is-open"]))
        : _createCommentVNode("", true),
      (_ctx.showSearch)
        ? (_openBlock(), _createBlock(_component_SearchModal, {
            key: 1,
            "onSearch-toggle": _cache[3] || (_cache[3] = $event => (_ctx.toggleSearch($event)))
          }))
        : _createCommentVNode("", true),
      _createVNode(_component_PauseShopModal, {
        "is-open": _ctx.showPauseShop,
        "onPause-shop-toggle": _cache[4] || (_cache[4] = $event => (_ctx.togglePauseShop($event)))
      }, null, 8, ["is-open"])
    ]),
    _: 1
  }))
}