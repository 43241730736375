
import { defineComponent } from 'vue';
import { IonButton } from '@ionic/vue';
import useConfigs from '@/services/config';

export default defineComponent({
    name: 'HeaderAlert',

    components: {
        IonButton,
    },

    props: {
        message: {
            type: String,
            default: '',
        },
        buttonText: {
            type: String,
            default: 'View',
        },
    },

    setup() {
        return {
            configs: useConfigs(),
        };
    },
});
