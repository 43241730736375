
import {
    IonSearchbar,
    IonBackdrop,
    IonContent,
    IonButton,
    IonIcon
} from '@ionic/vue';
import { defineComponent, unref } from 'vue';
import { shopOrders } from '@/services/firebase';
import OrdersTable from '@/components/OrdersTable/OrdersTable.vue';
import { FbOrderI } from '@/services/firebase/FirebaseService';

export default defineComponent({
    name: 'Search',
    components: { IonSearchbar, IonBackdrop, IonContent, OrdersTable, IonButton, IonIcon },
    setup() {
        return {
            allOrders: unref(shopOrders).all
        };
    },
    data: ()=>({
        orders: [] as FbOrderI[],
    }),
    mounted() {
        this.orders = this.allOrders;
    },
    methods: {
        updateResults($event: CustomEvent){
            if ($event.detail.value === '') {
                this.orders = this.allOrders;
            } else {
                this.orders = this.allOrders.filter(this.filterOrdersByTerm.bind(this, $event.detail.value));
            }
        },
        filterOrdersByTerm(query: string, order: FbOrderI) {
            if ( isNaN(Number(query)) ) {
                // query by names
                const name = `${order?.orderDetails?.customerFirstName} ${order?.orderDetails?.customerLastName}`.toLowerCase();
                const match = name.indexOf(query.toLowerCase()) > -1;
                return match;
            } else {
                // query by order numbers
                return order.orderDetails.orderNumber.indexOf(query) > -1;
            }

        }
    }
});
