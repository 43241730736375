
import { computed, defineComponent, inject, toRefs } from 'vue';
import {
    IonButton,
    IonButtons,
    IonHeader,
    IonIcon,
    IonLabel,
    IonToggle,
    IonToolbar,
    menuController
} from '@ionic/vue';
import { alertCircle, chevronBackSharp, search } from 'ionicons/icons';
import { Store } from '@/stores';
import useConfigs from '@/services/config';
import { hasHqMessages } from '@/services/messages';
import HeaderAlert from '@/components/Header/HeaderAlert.vue';
import PauseButton from '@/components/Buttons/PauseButton.vue';

export default defineComponent({
    name: 'Header',
    components: {
        HeaderAlert,
        IonButton,
        IonButtons,
        IonHeader,
        IonIcon,
        IonLabel,
        IonToggle,
        IonToolbar,
        PauseButton,
    },
    data() {
        return {
            title: 'Pickup Orders',
            backTitle: 'All Pickup Orders',
        };
    },
    props: {
        alertMessage: String,
        alertButtonText: String,
    },
    computed: {
        isHome(): boolean {
            return this.$route.path === '/orders';
        },
    },
    methods: {
        handleHomeLink() {
            if (this.isHome) {
                menuController.open();
            } else {
                this.$router.back();
            }
        }
    },
    setup(): object {
        const store = inject('store') as Store;
        const formattedTime = computed(() => {
            return store.state.currentTime.toLocaleTimeString([], {
                hour: '2-digit',
                minute: '2-digit',
            });
        });

        return {
            configs: useConfigs(),
            hasHqMessages,
            alertCircle,
            search,
            chevronBackSharp,
            ...toRefs(store.state),
            formattedTime,
            toggleCurbside: store.toggleCurbside,
        };
    },
});
