
import { defineComponent, inject, toRefs, ref, watch, onMounted } from 'vue';
import { Store } from '@/stores';
import {
    IonContent,
    IonBackdrop,
    IonButton,
    IonIcon,
    IonInput,
    IonList,
    IonLabel,
    IonListHeader,
    IonItem,
    IonRadio,
    IonRadioGroup,
} from '@ionic/vue';

export default defineComponent({
    name: 'PauseShop',

    components: {
        IonBackdrop,
        IonContent,
        IonButton,
        IonIcon,
        IonInput,
        IonList,
        IonListHeader,
        IonItem,
        IonLabel,
        IonRadio,
        IonRadioGroup
    },

    props: {
        isOpen: {
            type: Boolean,
            default: false,
        },
    },

    setup(props, { emit }): object {
        const store = inject('store') as Store;
        const { isPaused, pauseBlurb, pauseOptions, pauseReasons } = toRefs(
            store.pauseShopData
        );

        const type = ref('all');
        const reason = ref('');
        const otherReason = ref('');
        const employeeID = ref(''); // accepts phone number or employee id
        const text = ref('Pause Shop');

        const getText = () => {
            text.value = isPaused.value ? 'Unpause Shop' : 'Pause Shop';
        };

        const getDefaultReason = () => {
            reason.value = pauseReasons.value[0];
        };

        onMounted(() => {
            getText;
            getDefaultReason;
        });

        watch(() => isPaused.value, getText);

        watch(() => pauseReasons.value, getDefaultReason);

        const closeModal = () => {
            emit('pause-shop-toggle', false);
        };

        const setShopStatus = () => {
            store.postPauseShopStatus(type.value, reason.value, otherReason.value, employeeID.value)
            .then(() => {
                closeModal();
                // Reset component values
                type.value = 'all';
                reason.value = pauseReasons.value[0];
                otherReason.value = '';
                employeeID.value = '';
            });
        };

        return {
            store,
            closeModal,
            setShopStatus,
            isPaused,
            pauseBlurb,
            pauseOptions,
            pauseReasons,
            text,
            employeeID,
            type,
            reason,
            otherReason,
        };
    },
});
